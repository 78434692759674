<template>
  <div class="prize-saver-terms">

    <h1>Prize Saver Account Terms &amp; Conditions</h1>
    <ol start="1" type="1">
      <li>The Association of British Credit Unions Ltd (the organisers -
      together, "<strong>we</strong>", "<strong>us</strong>"), are providing a
      prize draw facility to Cash Box Credit Union. ABCUL are the holders of all
      intellectual property rights for PrizeSaver. Association of British Credit
      Unions Ltd will provide reasonable notice in advance to any participating
      credit union of any change taking effect if they have elected to either:
        <ol start="a" type="a" class="my-2">
          <li>terminate the scheme and cease to operate prize draws before the
            scheduled end date of the extension period or</li>
          <li>extend the scheme period.</li>
        </ol>
      </li>
      <li>
        We will operate a free monthly prize draw for all participating credit
        unions with a main prize of not less than £5,000 and at least twenty
        smaller prizes of not less than £20 each. The monthly prize draw will
        take place around the 15th of each month or the next working day,
        although in exceptional circumstances the prize draw may be postponed or
        cancelled. Winners will be selected via a randomised computer process
        from all valid entries from all the participating credit unions.
      </li>
      <li>
        In order to be eligible to enter the prize draw, you must:
        <ol start="a" type="a" class="my-2">
          <li>have a PrizeSaver account with us;</li>
          <li>hold your PrizeSaver account in your sole, personal capacity and
            not jointly with any other person or in connection with the operation of any
            business or commercial undertaking;
          </li>
          <li>be aged 18 or over;</li>
          <li>be resident in England and Wales or Scotland.</li>
        </ol>
      </li>
      <li>Employees or volunteers of Cash Box Credit Union and their family
        members cannot be included in the prize draw.</li>
      <li>
        By opening a PrizeSaver account with us, you are applying to participate
        in the prize draws and agree to comply with these prize terms. We will
        automatically enter you into any of the prize draws which you are
        eligible to enter. Participation in the prize draws will not affect any
        of the conditions of your PrizeSaver account, including the interest
        rates (if any) applicable to your PrizeSaver account.
      </li>
      <li>
        For every £1 that you have in your PrizeSaver account as at the end of
        each calendar month, you will have one entry into any prize draw that
        takes place in the following month. You can have a maximum of 200
        entries into the prize draw. This maximum amount applies regardless of
        whether you have more than £200 in your PrizeSaver account.
      </li>
      <li>
        You may only have one PrizeSaver account. If you open more than one
        PrizeSaver account or attempt to do so, you may be disqualified from
        entering into any prize draws.
      </li>
      <li>
        We may also arrange other prize draws in addition to the monthly prize
        draws. These other prize draws may be limited to particular credit
        unions or particular types of customer. We will enter you for any other
        prize draw for which you are eligible. Any other draws will be operated
        on the same terms as the monthly draws, but we reserve the right to
        increase the number or amount of the prizes. We will notify you of the
        details of any other prize draw which you may be entered for.
      </li>
      <li>
        You can find details of the prizes for each prize draw on our website
        <a href="/savings/prize-saver" target="_blank">cashbox.org.uk/savings/prize-saver</a> or by contacting our office by
        emailing
        <a href="mailto:enquiries@cashbox.org.uk">
          enquiries@cashbox.org.uk
        </a>
        or ringing us on 0161 342 3266
      </li>
      <li>
        We reserve the right to increase the amount or number of the prizes in a
        monthly prize draw (either for that particular prize draw or for future
        prize draws as well).  The minimum amount of each prize, the minimum
        number of prizes in any prize draw set out in paragraph 2 above and the
        value of each entry into a prize draw will not be changed prior to the
        end of the scheme, but we may amend any of the other terms of the prize
        draw, including the maximum number of entries each person can have in
        any prize draw.  We will notify you at least <strong>14 days</strong> in
        advance if the organisers notify us of any change to the monthly prize
        draw arrangements.  If you no longer wish to take part in future prize
        draws based on the amended terms, you will need to close your PrizeSaver
        account with us.
      </li>
      <li>
        We reserve the right to postpone a particular prize draw.  We expect
        that prize draws will not be postponed other than in exceptional
        circumstances, such as where there are technical or operational problems
        with the prize draw or if the prize draw cannot take place due to
        circumstances outside the organisers reasonable control. In the unlikely
        event that those circumstances cannot be remedied, the organisers
        reserve the right to cancel the prize draw.  We will notify you promptly
        but will not be responsible if the organisers choose to postpone or
        cancel any particular prize draw.  If a prize draw is postponed, we will
        notify you once the organisers have notified us that the prize draw is
        being re-run.
      </li>
      <li>
        The Association of British Credit Unions have the right to terminate or
        suspend the involvement of any participating credit union in the scheme.
        If the organisers exercise any of these rights in respect of Cash Box
        Credit Union, we will notify you as soon as possible, explaining the
        consequences for your participation in the prize draws, and you will
        have the option to close your PrizeSaver account.
      </li>
      <li>
        If you win a prize:
        <ol start="a" type="a" class="my-2">
          <li>
            We will normally write to you at the latest postal or email address
            we have for you in our records to notify you within 21 days of the prize
            draw taking place. We do not accept any responsibility if you do not receive
            this notification due to any technical reason or as a result of your address
            being incorrect or incomplete.
          </li>
          <li>
            If you win the headline prize in any prize draw:
            <ol start="i" type="i" class="my-2">
              <li>
                you must give us the details of a UK bank account or a UK credit
                union account in your name into which the prize must be paid.  You may
                nominate your PrizeSaver account for this purpose, but you may also use a
                different account in your own name if you prefer; and
              </li>
              <li>
                when we notify you that you have won, we will also tell you what the
                deadline is for you to give us the details of the account referred to in (i)
                above.  If you have not given us the information by that day, if you decline
                to accept the prize or if we have been unable to reach you after reasonable
                attempts using the contact details we have on record, you will forfeit the
                prize.
              </li>
            </ol>
          </li>
          <li>In relation to all prizes other than the headline prize, we will pay
            your prize money directly into your PrizeSaver account.
          </li>
          <li>We do not accept any responsibility if you are not able to take up a prize.</li>
        </ol>
      </li>
      <li>
        The decision regarding prize winners is final and no correspondence or
        discussion will be entered into, other than with the winners.
      </li>
      <li>
        Unless otherwise agreed in writing by us, a prize will only be awarded
        directly to the registered holder of the PrizeSaver account.
      </li>
      <li>
        If there is an error in relation to any prize draw, we may decide in its
        discretion whether it will re-run the prize draw.
      </li>
      <li>
        If, for whatever reason, you are left out of a monthly prize draw for
        which you would have been eligible, we will not be responsible to you
        for any potential prize you could have won.
      </li>
      <li>
        If you:
        <ol start="a" type="a" class="my-2">
          <li>open more than one PrizeSaver account or attempt to do so; or
          </li>
          <li>provide any information in relation to the prize draw which you know
            to be untrue; or
          </li>
          <li>if we otherwise suspect that you are acting fraudulently or
            dishonestly we may take steps to recover any money inappropriately awarded
            to you.  We may also close your PrizeSaver account, and you may be prevented
            from entering any future prize draws.
          </li>
        </ol>
      </li>
      <li>
        Winners may be requested to participate in future publicity but will not
        be required to do so.  We will contact you if we wish to ask you to
        participate in future publicity.
      </li>
      <li>
        If, at any time, you decide that you no longer wish to take part in
        future prize draws, you will need to close your PrizeSaver account. You
        will not incur any fees or charges by closing your PrizeSaver account.
      </li>
      <li>
        If you close your PrizeSaver account, you will automatically be excluded
        from any future prize draws.
      </li>
      <li>
        We may amend these terms at any time to reflect any changes to the
        arrangements for the prize draws that we make from time to time. You
        should check our website cashbox.org.uk from time to time to familiarise
        yourself with any changes to these terms.
      </li>
      <li>
        On termination or expiry of the scheme, the prize draws will cease, and
        we will transfer your PrizeSaver funds into your Share Account.
      </li>
      <li>
        In the event of a conflict between these prize terms and any other terms
        relating to your PrizeSaver account, these prize terms shall prevail.
      </li>
      <li>
        These terms shall be governed by English law, and the parties submit to
        the non-exclusive jurisdiction of the courts of England and Wales.  If
        you are resident in Scotland, you may also bring proceedings in the
        courts of Scotland.
      </li>
      <li>
        The promoter of the prize draws is Cash Box Credit Union, Denton Town
        Hall, Denton, M34 2AP.  The promoter shall have sole responsibility for
        the prize draws, but you agree that the organisers shall have the right
        to enforce any provision of these prize terms and participate in the
        conduct or resolution of any claim or dispute arising out of or in
        connection with the prize draws or the PrizeSaver account in its sole
        discretion as if it was a party to these terms.
      </li>
      <li>
        Cash Box Credit Union will use the information you provide to apply for
        your PrizeSaver account and any information that is generated or
        collected once your PrizeSaver account is opened (as applicable), to
        provide the PrizeSaver account, to communicate with you and to comply
        with applicable legal requirements. Cash Box Credit Union will also
        share your information with the organisers for the purposes of operating
        the prize draws and evaluating the effectiveness of the prize draws. For
        more information, please see our <a href="/savings/prize-saver/privacy-notice" target="_blank">Prize Draw Privacy Policy</a>.
      </li>
    </ol>

  </div>
</template>

<script>
export default {
  name: 'PrizeSaverTerms',
  metaInfo() {
    return {
      title: 'Prize Saver | Terms & Conditions | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>
ol > li {
  margin-block: .5rem;
}
</style>
